import deps from 'dependencies';
import op from 'object-path';
import groupBy from 'lodash/groupBy';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import { getTournamentDay } from 'appdir/components/general/Util';

const STATUS_REDEEMED = '';
const STATUS_EXPIRED = 'Expired';
const STATUS_ASSIGNED = 'Assigned to';
const STATUS_TRANSFERRED = 'Transferred to';
const STATUS_RETURNED = 'Returned to';
const STATUS_RETURNED_BY = 'Returned by';
const STATUS_RECEIVED = 'Received from';
const STATUS_TRANSFER_PENDING = 'Transfer Pending to';
const STATUS_CANCEL_PENDING = 'Cancel Pending';

export const FILTER_EXPIRED = 'Expired';
export const FILTER_ASSIGNED = 'Assigned';
export const FILTER_TRANSFERRED = 'Transferred';
export const FILTER_RETURNED = 'Returned';
export const FILTER_RECEIVED = 'Received';
export const FILTER_TRANSFER_PENDING = 'Transfer Pending';
export const FILTER_SHARED = 'Shared';
export const FILTER_SHARE_PENDING = 'Share Pending';
export const FILTER_CANCEL_PENDING = 'Cancel Pending';
export const FILTER_NORMAL = 'Normal';

const stubDetails = [
	{ key: 'Row', value: 'N/A' },
	{ key: 'Seat Number', value: 'N/A' },
	{ key: 'Court', value: 'N/A' },
	{ key: 'Gangway', value: 'N/A' },
	{ key: 'Stand', value: 'N/A' },
];

/**
 * format all loaded tickets
 * - moves all source data into a source attribute to differentiate source vs params we add for processing
 * @param {} result
 * @param {*} current
 * @param {*} data
 * @param {*} store
 * @returns
 */
export const formatTickets = (result, current, spectator, store) => {
	let tickets = [];
	const { Config, Tickets } = store.getState();
	//const { ticketLabels } = Tickets;
	let groupPrefix = Config.tickets.api.ticketGroupPrefix;

	// logger.log('[Tickets] services.formatTickets - groupPrefix:%o', groupPrefix);

	result.groups.forEach(group => {
		if (group.id.indexOf(groupPrefix) == 0) {
			group.events.forEach(event => {
				event.tickets.forEach(ticket => {
					//copy source ticket data in source param
					let params = [
						'activationParams',
						'controlledDate',
						'details',
						'externalId',
						'groupId',
						'id',
						'holder',
						'initialSpectator',
						'transfer',
						'transferRules',
						'state',
						'security',
						'purchase.priceCategory',
						'purchase.price',
					];
					params.forEach(param => {
						op.set(ticket, `source.${param}`, op.get(ticket, param, null));
						op.del(ticket, param);
					});

					//set other source values which can't be copied directly
					//  and changed values for ticket management
					op.set(ticket, `externalId`, op.get(ticket, 'source.externalId', ''));
					op.set(ticket, 'source.event.expirationDate', event.expirationDate);
					//op.set(ticket, 'source.event.expirationDate', '2022-06-30T23:59:00Z');
					op.set(ticket, 'source.event.startTime', event.startTime);
					op.set(ticket, 'source.event.name', event.name);
					op.set(ticket, 'spectatorId', spectator.id);
					op.set(ticket, 'spectatorName', `${spectator.firstName} ${spectator.lastName}`);

					//cleanup params do not need
					op.del(ticket, 'event');

					op.del(ticket, 'eventId');
					op.del(ticket, 'image');
					op.del(ticket, 'organizerDomainName');
					op.del(ticket, 'security');
					op.del(ticket, 'design');
					op.del(ticket, 'hold');
					op.del(ticket, 'mainApplicant');
					op.del(ticket, 'sortingKey');
					op.del(ticket, 'resaleRules');
					op.del(ticket, 'resaleToTicketShopRules');
					op.del(ticket, 'ticketActivationDetails');

					if (op.get(ticket, 'source.details.main', null) == null) {
						op.set(ticket, 'source.details.main', stubDetails);
					}

					ticket = appendDetailedInformation(ticket);

					if (current && current === true) {
						op.set(ticket, 'current', true);
					} else {
						op.set(ticket, 'current', false);
					}

					//update expiration for UGP controlled date
					// check for other business rules
					applyOtherRules(ticket);

					tickets.push(ticket);
				});
			});
		}
	});
	return tickets;
};

/**
 * format loaded transfer tickets that have a 'sent' value
 *  only want to proces and display transfer user has 'sent'
 * - moves all source data into a source attribute to differentiate source vs params we add for processing
 * @param {*} result
 * @param {*} current
 * @param {*} id
 * @param {*} store
 * @param {*} ticketData
 * @returns
 */
export const formatTransfers = (result, current, spectator, store, ticketData) => {
	let transfers = [];
	let sentTransfer = [];
	let temp2 = [];
	const { Config, Tickets } = store.getState();
	//const { ticketLabels } = Tickets;
	let groupPrefix = Config.tickets.api.ticketGroupPrefix;
	// JDA (1-15-22)
	// Why are we checking ticketGroupPrefix to eliminate tickets?
	//  prod prefeix is '' which allows all transfers

	//filter to tickets which are 'sent' and not 'returned'
	let temp = result.filter((ticket, i) => {
		return ticket.direction == 'sent';
	});

	//logger.log('[Tickets] services.formatTransfers - temp:%o', temp);

	/**
	 * remove tickets that are also in the ticketData
	 * from the list
	 */
	temp.forEach(t => {
		let found = ticketData.filter((td, i) => {
			return td.source.id == t.ticketId;
		});

		if (found.length == 0) {
			temp2.push(t);
		}
	});

	//logger.log('[Tickets] services.formatTransfers - temp2:%o', temp2);

	/**
	 * group the transfers by ticket id
	 */
	let grouped = groupBy(temp2, function(t) {
		return t.ticketId;
	});

	// logger.log('[Tickets] services.formatTransfers - grouped:%o', grouped);

	/**
	 * for the grouped tickets, for those with no duplicate ticket id in the
	 * list, put them in sentTransfer. if there are any with the same
	 * ticketId, sort them by date and grab the ticket with the latest
	 * date to add to sentTransfer
	 */

	Object.keys(grouped).map((value, i) => {
		if (grouped[value].length > 1) {
			// logger.log('[Tickets] services.formatTransfers - grouped value:%o', grouped[value]);

			let sortedGrouped = grouped[value].sort((a, b) => {
				let d1 = a.date;
				let d2 = b.date;

				if (moment(d1).isAfter(moment(d2))) return -1;
				if (moment(d2).isAfter(moment(d1))) return 1;
			});

			// logger.log('[Tickets] services.formatTransfers - sortedGrouped:%o', sortedGrouped);

			sentTransfer.push(sortedGrouped[0]);
		} else {
			sentTransfer.push(grouped[value][0]);
		}
	});

	//logger.log('[Tickets] services.formatTransfers - sentTransfer:%o', sentTransfer);

	sentTransfer.forEach(transfer => {
		let ticketGroupId = op.get(transfer, 'ticket.event.group.id', '');

		if (ticketGroupId.indexOf(groupPrefix) == 0) {
			//copy source ticket data in source param
			let params = [
				'activationParams',
				'details',
				'externalId',
				'groupId',
				'id',
				'holder',
				//'initialSpectator',
				'transferRules',
				'state',
				'purchase.priceCategory',
				'price',
			];
			params.forEach(param => {
				op.set(transfer, `source.${param}`, op.get(transfer, `ticket.${param}`, null));
				op.del(transfer, `ticket.${param}`);
			});

			//set other parameters
			//(there is no externalId in transfer data)
			op.set(transfer, `externalId`, op.get(transfer, 'ticketId', '').split('@')[1]);
			//op.set(transfer, `externalId`, null);
			op.set(transfer, 'source.event.expirationDate', transfer.ticket.event.expirationDate);
			//op.set(transfer, 'source.event.expirationDate', '2022-06-30T23:59:00Z');
			op.set(transfer, 'source.event.startTime', transfer.ticket.event.startTime);
			op.set(transfer, 'source.event.name', transfer.ticket.event.name);
			op.set(transfer, 'spectatorId', spectator.id);
			op.set(transfer, 'spectatorName', `${spectator.firstName} ${spectator.lastName}`);

			op.set(transfer, 'source.state', transfer.status);
			//op.set(transfer, 'source.purchase.priceCategory', null);

			//move price into purchase ndoe ot be consistent with tickets
			op.set(transfer, 'source.purchase.price', transfer.source.price);
			op.del(transfer, `source.price`);

			op.set(transfer, 'source.transfer.toEmail', transfer.to);
			op.set(transfer, 'source.transfer.direction', transfer.direction);
			op.set(transfer, 'source.transfer.toName', transfer.toName);
			op.set(transfer, 'source.transfer.date', transfer.date);

			op.set(transfer, 'source.initialSpectator.email', spectator.id);

			if (op.get(transfer, 'source.details.main', null) == null) {
				op.set(transfer, 'source.details.main', stubDetails);
			}

			//delete parameters not needed
			let deleteParam = [
				'nextAction',
				'netPrice',
				'netPayment',
				'processingCode',
				'price',
				'date',
				'resaleFee',
				'ticketId',
				'status',
				'to',
				'direction',
				'toName',
				// 'from',
				// 'fromName',
				'groupId',
				'ticket',
			];
			deleteParam.forEach(param => {
				op.del(transfer, `${param}`);
			});

			transfer = appendDetailedInformation(transfer);

			if (current && current === true) {
				op.set(transfer, 'current', true);
			} else {
				op.set(transfer, 'current', false);
			}

			applyOtherRules(transfer);

			//logger.log('[Tickets] services.formatTransfers - sentTransfer:%o', sentTransfer);
			transfers.push(transfer);
		}
	});
	//logger.log('[Tickets] services.formatTransfers - transfers:%o', transfers);
	return transfers;
};

/**
 * check if need to modify expirationDate
 * @param {*} ticket
 * @returns
 */
const applyOtherRules = ticket => {
	let hiddenDetails = ticket.source.details.hidden;

	let code = '';
	if (hiddenDetails) {
		code =
			hiddenDetails
				.filter((d, i) => {
					return d.key == 'productCode';
				})
				.map((d, i) => {
					return d.value;
				})[0] || '';
	}

	if (code.toLowerCase() == 'membungp') {
		ticket.detailedInformation.category = 'MEMBUNGP';

		if (ticket.source.controlledDate) {
			// if ticket is an UGP and has a controlled date, override the ticket expired date with 11:59:59 of the controlled day
			let date = moment(ticket.source.controlledDate).utc();
			let time = moment('23:59:59', 'HH:mm:ss');
			date.set({
				hour: time.get('hour'),
				minute: time.get('minute'),
				second: time.get('second'),
			});
			ticket.source.event.expirationDate = date.format('YYYY-MM-DDTHH:mm:ssZ');
		}
	}

	code = '';
	if (hiddenDetails) {
		code =
			hiddenDetails
				.filter((d, i) => {
					return d.key == 'SalesChannel';
				})
				.map((d, i) => {
					return d.value;
				})[0] || '';
	}
	if (code.toLowerCase() == 'testing') {
		ticket.testTicket = true;
	}
};

/**
 * return object representing ticket details
 */
const appendDetailedInformation = ticket => {
	let detailObj = {
		court: '',
		courtCode: '',
		gangway: '',
		row: '',
		seat: '',
		category: '',
	};
	let sourceDetails = ticket.source.details.main;
	let hidden = ticket.source.details.hidden;

	if (sourceDetails) {
		detailObj.court =
			sourceDetails
				.filter((d, i) => {
					return d.key == 'Court';
				})
				.map((d, i) => {
					return d.value;
				})[0] || '';
		detailObj.courtCode =
			sourceDetails
				.filter((d, i) => {
					return d.key == 'Court';
				})
				.map((d, i) => {
					return d.value;
				})[0] || '';
		detailObj.gangway =
			sourceDetails
				.filter((d, i) => {
					let lowercase_key = d.key.toLowerCase();
					return lowercase_key == 'gangway' || lowercase_key == 'block';
				})
				.map((d, i) => {
					return d.value.replace('Gangway ', '');
				})[0] || '';
		detailObj.stand =
			sourceDetails
				.filter((d, i) => {
					let lowercase_key = d.key.toLowerCase();
					return lowercase_key == 'stand';
				})
				.map((d, i) => {
					return d.value;
				})[0] || '';
		detailObj.row =
			sourceDetails
				.filter((d, i) => {
					/**
					 * if data has row data then, use that, otherwise use seat data if
					 * seat value has an '-'
					 */
					let lowercase_key = d.key.toLowerCase();
					return (
						lowercase_key.includes('row') ||
						(!lowercase_key.includes('row') && lowercase_key.includes('seat') && d.value.includes('-'))
					);
				})
				.map((d, i) => {
					let lowercase_key = d.key.toLowerCase();
					if (lowercase_key.includes('row')) {
						return d.value;
					} else if (lowercase_key.includes('seat')) {
						let tmp = d.value.split('-');
						return tmp[0];
					}
				})[0] || '';
		detailObj.seat =
			sourceDetails
				.filter((d, i) => {
					let lowercase_key = d.key.toLowerCase();
					return lowercase_key.includes('seat');
				})
				.map((d, i) => {
					if (d.value.includes('-')) {
						let tmp = d.value.split('-');
						return tmp[1];
					} else {
						return d.value;
					}
				})[0] || '';
	}
	if (hidden) {
		detailObj.category =
			hidden
				.filter((d, i) => {
					return d.key == 'categoryCode';
				})
				.map((d, i) => {
					return d.value;
				})[0] || '';
	}

	//hide seat number for grounds passes
	if (detailObj.courtCode.toLowerCase() == "grounds") {
		detailObj.seat = ''
	}

	ticket.detailedInformation = detailObj;
	return ticket;
};

/**
 * calculate status values for the ticket
 * @param {*} tickets
 * @param {*} id
 * @returns
 */
export const appendStatus = (tickets, id, config) => {
	tickets.forEach(ticket => {
		let statusObj = { value: STATUS_REDEEMED, detail: null, filtervalue: null };
		let hidden = op.get(ticket, 'source.details.hidden', []) || [];
		let ticketHolder = op.get(ticket, 'source.holder.identity', '');
		let expirationDate = op.get(ticket, 'source.event.expirationDate', null);

		let cancelPending =
			hidden
				.filter(obj => obj.key == 'cancelStatus')
				.map(obj => {
					return obj.value == 'Cancel - Pending';
				})[0] || false;
		let returnedTicket =
			hidden
				.filter(d => d.key == 'transfer_extra')
				.map((d, i) => {
					return (d.value == 'tixngo_forbid_return' || (d.value.indexOf('/s/tickets/return.html') > 0));
				})[0] || false;

		// not using assigned ticketholder in 2022
		ticketHolder = null;

		//if ticket has hidden cancelPending set to true
		if (cancelPending) {
			statusObj = { value: STATUS_CANCEL_PENDING, filtervalue: FILTER_CANCEL_PENDING };
		}
		//if the ticket state is pending, then ticket is pending transfer
		//  not using share vs transfer in 2022, all ticket types use transfer
		else if (
			op.get(ticket, 'source.state', null) == 'pending' &&
			op.get(ticket, 'source.transfer.toEmail', false)
		) {
			statusObj = {
				value: STATUS_TRANSFER_PENDING,
				detail: op.get(ticket, 'source.transfer.toEmail', ''),
				filtervalue: FILTER_TRANSFER_PENDING,
			};
		}
		//if a  ticket has been transferred, will have a toEmail and not be a returned ticket
		else if (
			op.get(ticket, 'source.transfer.toEmail', false) &&
			op.get(ticket, 'source.state', null) == 'confirmed' &&
			!returnedTicket
		) {
			statusObj = {
				value: STATUS_TRANSFERRED,
				detail: op.get(ticket, 'source.transfer.toEmail', ''),
				filtervalue: FILTER_TRANSFERRED,
			};
		}
		//if a  ticket has been returned, will have a toEmail and a transfer_extra value of 'tixngo_forbid_return'
		//   or a return url link
		else if (
			op.get(ticket, 'source.transfer.toEmail', false) &&
			op.get(ticket, 'source.state', null) == 'confirmed' &&
			returnedTicket
		) {
			statusObj = {
				value: STATUS_RETURNED,
				detail: op.get(ticket, 'source.transfer.toEmail', ''),
				filtervalue: FILTER_RETURNED,
			};
		}
		// if a  ticket was transferred and has now been returned, will have a fromEmail, and a transfer_extra value of 'tixngo_forbid_return'
		//   or a return url link, and a status of 'normal'
		else if (
			op.get(ticket, 'source.transfer.fromEmail', false)&&
			op.get(ticket, 'source.state', null) == 'normal' &&
			returnedTicket
		) {
			statusObj = {
				value: STATUS_RETURNED_BY,
				detail: op.get(ticket, 'source.transfer.fromEmail', ''),
				filtervalue: FILTER_RETURNED,
			};
		}
		//if was a received ticket will have a fromEmail
		else if (
			op.get(ticket, 'source.state', '') == 'normal' &&
			op.get(ticket, 'source.transfer.fromEmail', false) &&
			!returnedTicket
		) {
			statusObj = {
				value: STATUS_RECEIVED,
				detail: op.get(ticket, 'source.transfer.fromEmail', ''),
				filtervalue: FILTER_RECEIVED,
			};
		}

		//if has an assigned ticketholder and hasn't been shared or transferred
		else if (ticketHolder) {
			statusObj = {
				value: STATUS_ASSIGNED,
				detail: `${ticketHolder.firstName} ${ticketHolder.lastName}`,
				filtervalue: FILTER_ASSIGNED,
			};
		}
		//if ticket is just normal
		else if (
			op.get(ticket, 'source.state', null) == 'normal' ||
			op.get(ticket, 'source.state', null) == 'controlled'
		) {
			statusObj = { filtervalue: FILTER_NORMAL };
		}

		// if current time is after expiration date
		//  don't set on transferred tickets
		if (statusObj.value != STATUS_TRANSFERRED && expirationDate && moment().isAfter(moment(expirationDate))) {
			statusObj = { value: STATUS_EXPIRED, filtervalue: FILTER_EXPIRED, detail: null };
		}

		//add date if there is one
		statusObj.date = op.get(ticket, 'source.transfer.date', null);

		// add formatted date if date exists
		statusObj.dateFormatted = statusObj.date ? moment(statusObj.date)?.format('ddd DD MMM YYYY') : null;

		// add full status string if status.filtervalue != normal
		statusObj.fullStatusDetails =
			statusObj?.filtervalue !== 'Normal' ? `${statusObj?.value} ${statusObj?.detail}` : 'Normal';

		ticket.status = statusObj;
		appendActions(ticket, id, config);
	});
	return tickets;
};

/**
 * determine which actions are allowed based on ticket details/status
 * @param {*} ticket
 * @param {*} accountEmail
 * @returns
 */
const appendActions = (ticket, accountEmail, config) => {
	let actions = [];

	// let accountEmail = this.context.spectatorId; //comes from user profile/selection of account
	let initialSpectatorEmail = op.get(ticket, 'source.initialSpectator.email', '');
	let hidden = op.get(ticket, 'source.details.hidden', []) || [];
	let isCancellable =
		hidden
			.filter(d => d.key == 'isCancellable')
			.map((d, i) => {
				return d.value == 'true';
			})[0] || false;
	let transferLevel = op.get(ticket, 'source.transferRules.transferLevel', 0);
	let maxTransfers = op.get(ticket, 'source.transferRules.maxNumberOfTransferPerTicketInGroupId', -1);
	let isTransferrable = op.get(ticket, 'source.transferRules.allowTransfer', false);
	let allowTransfer =
		op.get(ticket, 'source.transferRules.allowTransfer', false) &&
		(transferLevel < maxTransfers || maxTransfers < 0);
	let ticketHolder = op.get(ticket, 'source.holder.identity', null);
	let returnedTicket =
		hidden
			.filter(d => d.key == 'transfer_extra')
			.map((d, i) => {
				return d.value == 'tixngo_forbid_return' || d.value.indexOf('/s/tickets/return.html') > 0;
			})[0] || false;

	// all tickets can be requested to be cancelled in 2022
	isCancellable = true;

	// no actions allowed if cancel pending
	if (ticket.status.value == STATUS_CANCEL_PENDING) {
		actions.push('none-pending-cancellation');
	}

	// no actions allowed after ticket has been used
	if (ticket.source?.state == 'controlled') {
		actions.push('none-controlled');
	}

	// no actions allowed after ticket has expired
	if (ticket.status.value == STATUS_EXPIRED) {
		actions.push('none-expired');
	}

	// if ticket not pending cancel
	//  and not used
	//  and not expired
	//  check for allowed actions
	if (ticket.status.value != STATUS_CANCEL_PENDING && ticket.status.value != STATUS_EXPIRED) {
		if (ticket.source?.state != 'controlled' && !ticket?.source?.controlledDate) {
			/**
			* user can cancel tickets
			* if not a debenture ticket and
			* - holder is original spectator 
			* - isCancellable = true
			* - ticket state is normal
			*
			* if a debenture ticket and
			* - ticket is a current ticket
			* - isCancellable = true

			* 2022 change, any initialSpectator can request cancellation
			*/
			//if (
			//((initialSpectatorEmail == accountEmail && ticket.source?.state == 'normal') ||
			//	(op.get(ticket, 'type', '') == 'debenture' && op.get(ticket, 'current', false))) &&
			if (
				initialSpectatorEmail == accountEmail &&
				(ticket.source?.state == 'normal' || ticket.source?.state == 'confirmed') &&
				isCancellable
			) {
				actions.push('cancel');
			}

			// if ticket transferred to current spectator
			//  2022 change - add check for returned ticket before allowing return
			if (
				ticket.source?.state == 'normal' &&
				op.get(ticket, 'source.transfer.fromEmail', false) &&
				!ticketHolder &&
				!returnedTicket
			) {
				actions.push('return');
			}

			//if not a debenture ticket and transfer pending, allow recall
			//  may not allow at all in 2022
			if (
				op.get(ticket, 'type', '') != 'debenture' &&
				op.get(ticket, 'status.value', null) == STATUS_TRANSFER_PENDING
			) {
				actions.push('recall');
			}

			//else allow transfer depending on on type
			if (ticket.source?.state == 'normal' && allowTransfer && !ticketHolder) {
				actions.push('transfer');
			} else {
				actions.push('transfer disallowed');
			}
		}

		//ticket is controlled, which is required for swap
		else {
			// to be swappable, ticket must be:
			// - ticket state must be 'controlled'
			// - ticket must not be expired
			// - `allowTransferAfterControl` = true
			// - `maxNumberOfTransferPerTicketInGroupId` = -1 (unlimited transfers)
			// - ticket has not been scanned out (requires integration of new data)
			// - must respect keepOneInGroup setting (prevent swap if last in group and keepOne is true) -- this is handled in front end

			if (
				config?.enableSwap &&
				ticket?.source?.event?.expirationDate &&
				moment().isBefore(moment(ticket?.source?.event?.expirationDate)) &&
				ticket?.source?.transferRules?.allowTransferAfterControl &&
				ticket?.source?.transferRules?.maxNumberOfTransferPerTicketInGroupId === -1
			) {
				actions.push('swap');
			}
		}
	}

	ticket.actions = actions;
	return ticket;
};

export const updateCourtLabels = (tickets, courts) => {
	//logger.log('[Tickets] services.updateTicketLabels - tickets:%0', tickets);
	//logger.log('[Tickets] services.updateTicketLabels - courts:%0', courts);

	tickets.forEach(ticket => {
		ticket.detailedInformation.court =
			courts.filter(crt => {
				if (crt.code.toLowerCase() == ticket.detailedInformation.court.toLowerCase()) {
					return true;
				}
			})[0]?.courtName || 'Unknown';
	});

	return tickets;
};

export const updateTicketLabels = (tickets, ticketLabels) => {
	logger.log('[Tickets] services.updateTicketLabels - tickets:%0', tickets);
	logger.log('[Tickets] services.updateTicketLabels - ticketLabels:%0', ticketLabels);

	let labelData;
	tickets.forEach(ticket => {
		labelData = ticketLabels.filter(label => label.code == ticket.detailedInformation.category)[0] || null;

		if (labelData) {
			ticket.label = labelData.label;
			ticket.spectatorName = labelData.show_name ? ticket.spectatorName : '';
			ticket.message = labelData.msg ? labelData.msg : null;

			if (labelData.complimentary === true) {
				ticket.price = 'Complimentary';
			} else if (labelData.hide_price === true) {
				ticket.price = '';
			} else {
				//logger.log('[Tickets] services.updateTicketLabels - ticket:%0', ticket);
				let price = String(ticket.source.purchase.price.amount).split('.');
				let dispPrice = price;
				if (price.length > 1) {
					if (price[1].length == 1) {
						price[1] = price[1] + '0';
					}
					dispPrice = price.join('.');
				} else {
					dispPrice = price[0] + '.00';
				}

				ticket.price = dispPrice + ' ' + ticket.source.purchase.price.currency;
			}

		} else {
			ticket.label = '';
			ticket.price = '';
		}
	});

	return tickets;
};

// find the ticket header value
// in 2024 change to set header image 'name' to gen or quals, as will only have 2 images vs one for each day as in 2023
export const updateTicketHeader = (tickets, ticketLabels, ticketTypes) => {
	//logger.log('[Tickets] services.updateTicketHeader - tickets:%0', tickets);
	logger.log('[Tickets] services.updateTicketHeader - ticketTypes:%0', ticketTypes);

	let labelData;
	let day = '';
	tickets.forEach(ticket => {
		labelData = ticketLabels.filter(label => label.code == ticket.detailedInformation.category)[0] || null;

		if (labelData?.header == 'QUALS') {
			if (parseInt(ticket.headerDay) >= 1 && parseInt(ticket.headerDay) <= 4) {
				day = `_${ticket.headerDay}`;
			}
			delete ticket.headerDay;
		} else {
			if (parseInt(ticket.day) >= 1 && parseInt(ticket.day) <= 14) {
				day = `_${ticket.day}`;
			}
		}

		logger.log('[Tickets] services.updateTicketHeader - labelData:%0', labelData);
		let typeDef = ticketTypes.filter(ticketType => 'GEN' == ticketType.type)[0] || null;
		let type = ticketTypes.filter(ticketType => labelData?.header == ticketType.type)[0] || null;
		if (labelData) {
			//define the header type, quals or gen.  used to differentiate design
			ticket.headerDesign = labelData.header == 'QUALS' ? 'QUALS' : 'GEN';
			ticket.headerLabel = type ? type.label : typeDef.label;
			ticket.colorBar = type ? type.colorBar : typeDef.colorBar;
			ticket.colorTxt = type ? type.colorTxt : typeDef.colorTxt;
			ticket.colorBg = type ? type.colorBg : typeDef.colorBg;
		} else {
			ticket.headerDesign = `gen`;
			ticket.headerLabel = type ? type.label : typeDef.label;
			ticket.colorBar = typeDef.colorBar;
			ticket.colorTxt = typeDef.colorTxt;
			ticket.colorBg = typeDef.colorBg;
		}
		if (ticket.day == 'UGP') {
			ticket.headerDesign = `gen`;
		}
		logger.log('[Tickets] services.updateTicketHeader - ticket:%0', ticket);
	});

	return tickets;
};

// update the ticket day value
export const updateTicketDay = (tickets, tournStart, qualStart, qualCode) => {
	tickets.forEach(ticket => {
		ticket.day = getTournamentDay(ticket.source.event.startTime, tournStart);
		ticket.date = moment(ticket.source.event.startTime)
			.tz('Europe/London')
			.format('ddd, DD MMM');

		let hiddenDetails = ticket.source.details.hidden;
		let code = '';
		if (hiddenDetails) {
			code =
				hiddenDetails
					.filter((d, i) => {
						return d.key == 'productCode';
					})
					.map((d, i) => {
						return d.value;
					})[0] || '';
		}

		if (code.toLowerCase() == 'membungp') {
			ticket.day = 'UGP';
		} else if (code == qualCode) { // qualCode from config value
			ticket.day = 'QUALS';
			ticket.headerDay = getTournamentDay(ticket.source.event.startTime, qualStart);
		}
	});

	return tickets;
};

/**
 * find the visit status based on a users ticket list and the current day
 * @param {*} tickets
 * @param {*} serverTime
 * @returns
 */
export const getVisitStatus = (tickets, serverTime) => {
	tickets = tickets.sort(function(a, b) {
		return moment(a.source.event.startTime).isBefore(b.source.event.startTime);
	});

	let visitStatus;

	if (tickets.length > 0) {
		let firstTicket = moment(tickets[0].source.event.startTime);
		let lastTicket = moment(tickets[tickets.length - 1].source.event.startTime);
		let server = moment.unix(serverTime);

		if (server.isBefore(firstTicket)) {
			visitStatus = 'pre-visit';
		} else if (server.isAfter(lastTicket)) {
			visitStatus = 'post-visit';
		} else {
			visitStatus = 'visit';
		}
	}
	return visitStatus;
};

/**
 * make sure tickets are sorted by day then court
 */
export const updateTicketSort = tickets => {
	tickets = tickets.sort(function(a, b) {
		let timeComp =
			moment(a.source.event.startTime).diff(b.source.event.startTime, 'day') || a.prestige - b.prestige;
		return timeComp;
	});
	//logger.log('[Tickets] services.updateTicketSort - tickets:%0', tickets);
	return tickets;
};



/**
 * get the action date from history and append ot ticket data
 * @param {*} tickets
 * @param {*} history
 */
export const appendActionDate = (tickets, history) => {
	//loop through tickets
	tickets.forEach(ticket => {
		let matched = history.filter((td, i) => {
			return td.ticketId == ticket.source.id;
		});

		if (matched.length > 0) {
			//sort so latest action is first
			let sorted = matched.sort((a, b) => {
				let d1 = a.date;
				let d2 = b.date;

				if (moment(d1).isAfter(moment(d2))) return -1;
				if (moment(d2).isAfter(moment(d1))) return 1;
			});

			if (sorted[0].direction == 'received') {
				//logger.log('[Tickets] services.appendActionDate ticket:%o matched:%o', ticket.source.id, sorted[0]);
				op.set(ticket, 'source.transfer.date', sorted[0].date);
			}
		}
	});

	return tickets;
};
import React from 'react';
import Debenture from 'appdir/components/cms/Debenture';
import DebenturePrivate from 'appdir/components/cms/DebenturePrivate';
import RegisterButton from 'appdir/components/cms/RegisterButton';
import GenericButton from 'appdir/components/cms/GenericButton';
import RestaurantBooking from 'appdir/components/cms/RestaurantBooking';
import Collapsible from 'appdir/components/cms/CollapsibleComponent';
import TabNavigation from 'appdir/components/cms/TabNavigation';

const RestaurantButtons = ({ currentUser }) => {
	logger.log('[RestaurantBooking] currentUser:%o', currentUser);

	return (
		<div className="content-main news article ">
			<div className=" column-layout ">
				<div className="one-col">
					<h3>New:</h3>
					Members Lawn:
					<br />
					<RestaurantBooking data={{ tag: 'memberslawn' }} />
					<br />
					The Baseline:
					<br />
					<RestaurantBooking data={{ tag: 'thebaselineuk' }} />
					<br />
					Cafe Pergola
					<br />
					<RestaurantBooking data={{ tag: 'thepergola' }} />
					<br />
					The Terrace
					<br />
					<RestaurantBooking data={{ tag: 'theterrace' }} />
					<br />
					The Gallery
					<br />
					<RestaurantBooking data={{ tag: 'thegallerywimbledon' }} />
					<br />
					The Roof Top
					<br />
					<RestaurantBooking data={{ tag: 'rooftopbar' }} />
					<br />
					The Lounge
					<br />
					<RestaurantBooking data={{ tag: 'number1debentureslounge' }} />
					<br />
					The No.1 Bar
					<br />
					<RestaurantBooking data={{ tag: 'number1debenturesbar' }} />
					<br />
					The Veranda
					<br />
					<RestaurantBooking data={{ tag: 'theverandauk' }} />
					<br />
					Centenary Brasserie
					<br />
					<RestaurantBooking data={{ tag: 'centenarybrasserie' }} />
				</div>

				<div className="one-col">
					<h3>No Login:</h3>
					Wimbledon Member Brasserie:
					<br />
					<RestaurantBooking data={{ tag: 'wimbledonmembersbrasserie' }} />
					<br />
					Wimbledon Member Brasserie Test:
					<br />
					<RestaurantBooking data={{ tag: 'wimbledonmembersbrasserietest' }} />
					<br />
					Members Resturant:
					<br />
					<RestaurantBooking data={{ tag: 'memberswimbledon' }} />
				</div>
				<div className="one-col">
					<h3>Any Role:</h3>
					Picnic:
					<br />
					<RestaurantBooking data={{ tag: 'wimbledonpicnic', id: '*' }} />
					<br />
					Picnic Test:
					<br />
					<RestaurantBooking data={{ tag: 'wimbledonpicnictest', id: '*' }} />
					<br />
					Wingfield:
					<br />
					<RestaurantBooking data={{ tag: 'wingfieldwimbledon', id: '*,' }} />
					<br />
					Centenary Seafood Bar
					<br />
					<RestaurantBooking data={{ tag: 'centenaryseafood', id: '*,' }} />
					<br />
					The Cavendish:
					<br />
					<RestaurantBooking data={{ tag: 'thecavendish', id: '*,' }} />
					<br />
				</div>
				<div className="one-col">
					<h3>Debenture Roles:</h3>
					Champions Room:
					<br />
					<RestaurantBooking data={{ tag: 'championsroomsuk', id: ['1000021', '1000023'] }} />
					<br />
					Champions Room Test:
					<br />
					<RestaurantBooking data={{ tag: 'championsroom', id: ['1000021', '1000023'] }} />
					<br />
					Renshaw:
					<br />
					<RestaurantBooking data={{ tag: 'renshawwimbledon', id: ['1000021', '1000022', '1000023'] }} />
					<br />
					Courtside:
					<br />
					<RestaurantBooking data={{ tag: 'courtsiderestaurantuk', id: ['1000021', '1000023'] }} />
					<br />
					Courtside Test:
					<br />
					<RestaurantBooking data={{ tag: 'wimbledoncourtsiderestaurant', id: ['1000021', '1000023'] }} />
				</div>
			</div>
		</div>
	);
};

export default RestaurantButtons;

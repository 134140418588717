import React, { useEffect, useState } from 'react';
import op from 'object-path';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import HorizontalMenuBar from '../../../general/HorizontalMenuBar';

/*
NOTE:
	change in config_web each year for day nav (tournStartDate, tournStart, tournIsoStart, qualIsoStart, qualCode):
		"dateConfig": {
				"hospitality": {
					"programme": "Friday 30th May 2025",
					"cushion": "Friday 7th June 2024",
					"parking": "Friday 6th June 2025",
					"ticket": "XX",
					"tournStartDate": "June 30",
					"year": "2025"
				},
				"media": {
					"year": "2025"
				},
				"displayDates": "{{displayDates}}",
				"tournStart": "30 June 2025",
				"tournIsoStart": "2025-06-30T11:00:00Z",
				"qualIsoStart": "2025-06-23T11:00:00Z",
				"qualCode": "QUAL25" 
			},
*/

const DayNavigator = props => {
	const [state, setState] = useState();

	useEffect(() => {
		logger.log('[Tickets - DayNavigator] useEffect - props:%o', props);

		// sort ticket days to make sure in proper order
		let daysSorted = op.get(props, 'tickets', []).sort((a, b) => moment(a.date) - moment(b.date))

		let days = daysSorted?.map(ticket => {
			const tournyDay =
				ticket.day === 'UGP'
					? 'ugp'
					: moment(ticket.source.event.startTime)
							.utc()
							.startOf('day')
							.valueOf();
			return [tournyDay, ticket.day];
		});

		let stringArray = days.map(JSON.stringify);
		let uniqueStringArray = new Set(stringArray);
		let uniqueArray = Array.from(uniqueStringArray, JSON.parse);

		const nums = uniqueArray.filter(n => typeof n[1] == 'number').sort(); // If the data type of a given element is a number store it in this array (and then sort)
		const non_nums = uniqueArray.filter(x => typeof x[1] != 'number').sort((a, b) => a[1] - b[1]); // Store everything that is not a number in an array (and then sort)

		days = [...non_nums, ...nums]; // combine the two arrays

		setState({
			days: days,
		});
		//logger.log('[Tickets - DayNavigator] useEffect - days:%o', days);
	}, [props.tickets]);

	let nextFutureDate;
	let { filteredDay, currentSelected } = props;

	let selectedDateStr;

	if (op.get(state, 'days', []).length > 0) {
		logger.log('[Tickets - DayNavigator] render - state:%o', state);
		//logger.log('[Tickets - DayNavigator] render - currentSelected:%o', currentSelected)

		let dateValuesToRender = state.days.map((day, index) => {
			// If the serverTime matches a day in the navigator, that is the default day.
			if (!currentSelected && !filteredDay) {
				// If there is no match, the default day should be the next future day in the navigator (look for next day for which the user has tickets)
				let endOf = moment(day[0]).endOf('day');
				nextFutureDate = !nextFutureDate && moment().isBefore(endOf) ? day : nextFutureDate;
			}

			// format('ddd') = day of week
			let val1 =
				!isNaN(day[1]) || day[1] === 'QUALS'
					? moment(day[0])
							.tz('Europe/London')
							.format('ddd')
					: 'Members';
			// format('DD') = day of month
			let val2 =
				!isNaN(day[1]) || day[1] === 'QUALS'
					? moment(day[0])
							.tz('Europe/London')
							.format('DD')
					: 'Grounds';
			let val3 = !isNaN(day[1]) ? `Day ${day[1]}` : day[1] === 'UGP' ? 'Passes' : 'Quals';

			let toShowDividerRight =
				day[1] === 'QUALS' &&
				index < state.days.length - 2 &&
				state.days[index + 1] &&
				state.days[index + 1][1] &&
				state.days[index + 1][1] !== 'QUALS';
			let toShowDividerLeft =
				day[1] !== 'UGP' &&
				day[1] !== 'QUALS' &&
				index > 0 &&
				state.days[index - 1] &&
				state.days[index - 1][1] &&
				state.days[index - 1][1] === 'UGP';

			const isCurrentlySelected = props.currentSelected === `${day[0]}-${day[1]}`;
			if (isCurrentlySelected) selectedDateStr = `${val1} ${val2}`;

			return (
				<div
					className={isCurrentlySelected ? 'hmb-selected' : ''}
					id={`${day[0]}-${day[1]}`}
					style={{
						borderRight: toShowDividerRight ? '1px solid rgba(255,255,255,0.30)' : '',
						borderLeft: toShowDividerLeft ? '1px solid rgba(255,255,255,0.30)' : '',
					}}>
					<div
						className={
							day[1] === 'UGP'
								? 'tickets-date-selector-details-ugp'
								: 'tickets-date-selector-details-small'
						}>
						{val1}
					</div>
					<div
						className={
							day[1] === 'UGP'
								? 'tickets-date-selector-details-ugp'
								: 'tickets-date-selector-details-large'
						}>
						{val2}
					</div>
					<div
						className={
							day[1] === 'UGP'
								? 'tickets-date-selector-details-ugp-large'
								: 'tickets-date-selector-details-small'
						}>
						{val3}
					</div>
				</div>
			);
		});

		// If the serverTime matches a day in the navigator, that is the default day.
		// if no match, use next future day in navigator
		// OR if there are no future days in the navigator, the default day should be the last day in the navigator.
		let defaultDateToUse = filteredDay
			? filteredDay
			: nextFutureDate
			? nextFutureDate
			: state.days[state.days.length - 1];

		if (props.currentSelected === '') props.onSelectDate(`${defaultDateToUse[0]}-${defaultDateToUse[1]}`);

		return (
			<div style={{ display: props.hideDisplay ? 'none' : 'block' }}>
				<HorizontalMenuBar
					data={dateValuesToRender}
					onSelectDate={val => props.onSelectDate(val)}
					currentlySelected={props.currentSelected}
					tabIndexEnabled={props.tabIndexEnabled}
					onTabIndexUpdate={value => props.onTabIndexUpdate(value)}
					selectedDateStr={selectedDateStr}
				/>
			</div>
		);
	} else {
		return null;
	}
};
export default DayNavigator;

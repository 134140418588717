import React, { useEffect, useState } from 'react';
import op from 'object-path';

import WebViewTicketStub from '../../../webviews/TicketsWebview/WebViewTicketStub';
import TicketSwapInitial from '../elements/TicketActionViews/TicketSwapAction/TicketSwapInitial';
import { courtImageObj } from 'appdir/components/pages/Tickets/elements/consts.js'

const TicketList = props => {
	//logger.log('[Tickets - DayNavigator] props:%o', props);

	const [state, setState] = useState();

	const [ticketActionsSelected, setTicketActionsSelected] = useState('');
	const [ticketModalOpen, setTicketModalOpen] = useState('');
	const [showTicketSwap, setShowTicketSwap] = useState(false);
	const [ticketSwapData, setTicketData] = useState('');
	const [ticketSwapStub, setTicketStub] = useState('');

	useEffect(() => {
		logger.log('[Tickets - TicketList] useEffect - props:%o', props);

		setState({
			tickets: props.tickets,
			ticketActionsSelected: '',
		});
	}, [props.tickets]);

	useEffect(() => {
		const onClick = event => {
			if (
				!event?.target?.className?.includes('ticket-stub') &&
				!event?.target?.className?.includes('ticket-action-modal') &&
				!event?.target?.className?.includes('ticket-modal')
			) {
				// close actions if anywhere is clicked (except ticket stub itself and action modal)
				setTicketActionsSelected('');
			}
		};
		document.body.addEventListener('click', onClick);

		return () => {
			document.body.removeEventListener('click', onClick);
		};
	}, []);

	const renderWebViewStub = (value, imageObjVal, index, isDisabled) => {
		return (
			<WebViewTicketStub
				middleContents={{
					court: value.detailedInformation.court,
					gangway: value.detailedInformation.gangway ? `Gangway ${value.detailedInformation.gangway}` : '',
					row: value.detailedInformation.row ? `Row ${value.detailedInformation.row}` : '',
					seat: value.detailedInformation.seat ? `Seat ${value.detailedInformation.seat}` : '',
					status: value.status,
				}}
				imageToRender={
					courtImageObj[imageObjVal ? imageObjVal.value.toLowerCase() : ''] ||
					'/assets/images/tickets/Tickets_GroundsPass.png'
				} // have a default image in case no match
				rightContents="ACTIONS"
				actions={value.actions}
				ticketActionsSelected={ticketActionsSelected}
				id={value.externalId}
				setTicketActionsSelected={setTicketActionsSelected}
				fullTicketValue={value}
				currentlySelected={ticketActionsSelected}
				ticketModalOpen={ticketModalOpen}
				setTicketModalOpen={setTicketModalOpen}
				index={index}
				onRefreshData={() => props.onRefreshData()}
				debugView={props.debugView}
				ticketData={props.tickets}
				onSoftRefreshData={() => props.onSoftRefreshData()}
				setShowTicketSwap={val => {
					setShowTicketSwap(val);
					props.onHideDayNavigator(val);
				}}
				setTicketValueData={(ticketData, ticketStub) => {
					setTicketData(ticketData);
					setTicketStub(ticketStub);
				}}
				disabled={isDisabled}
			/>
		);
	};

	let transferredTicketsLength = 0;
	let transferredTickets = [];
	let returnedTicketsLength = 0;
	let returnedTickets = [];

	if (op.get(state, 'tickets', []).length > 0 && props.selected) {
		logger.log('[Tickets - TicketList] render - state:%o', state);

		let arrayTicketTypes = [];
		if (props.ticketDisplayOrg[props.selected]) {
			for (const property in props.ticketDisplayOrg[props.selected]) {
				arrayTicketTypes.push({ [property]: props.ticketDisplayOrg[props.selected][property] });
			}
		}

		arrayTicketTypes.sort((a, b) => {
			return a[Object.keys(a)[0]][0].prestige - b[Object.keys(b)[0]][0].prestige;
		});

		// loop through arrayTicketTypes
		let renderTicketStubNonTransfer;
		let renderTicketStubTransfer;
		let renderTicketStubReturned;

		if (props.ticketDisplayOrg && arrayTicketTypes) {
			renderTicketStubNonTransfer = arrayTicketTypes.map((ticketValue, index) => {
				const currTicketName = Object.keys(ticketValue);

				// filter non-transferred tickets
				let nonTransferredTickets = ticketValue[currTicketName].filter(ticket => {
					let status = op.get(ticket, 'status', {});
					let filterValue = status?.filtervalue;
					let value = status?.value;
				
					// Exclude tickets with { filtervalue: 'transferred' }
					if (filterValue?.toLowerCase() === 'transferred') {
						return false;
					}
				
					// Include tickets with { filtervalue: 'returned', value: 'returned by' }
					if (filterValue?.toLowerCase() === 'returned') {
						return value?.toLowerCase() === 'returned by';
					}
				
					// Include all other tickets
					return true;
				});

				// filter transferred tickets
				let transferredTicketsNew = ticketValue[currTicketName].filter(ticket => {
					return ticket?.status?.filtervalue?.toLowerCase().includes('transferred');
				});
				transferredTickets = transferredTickets.concat(transferredTicketsNew);

				// filter transferred tickets
				let returnedTicketsNew = ticketValue[currTicketName].filter(ticket => {
					// if filtervalue == returned, make sure its not a "returned BY" sender -- just filter out returned to
					return ((ticket?.status?.filtervalue?.toLowerCase().includes('returned')) && (!ticket?.status?.value?.toLowerCase().includes('returned by')));
				});
				returnedTickets = returnedTickets.concat(returnedTicketsNew);

				return (
					<div
						key={`ticket-view-container-${index}`}
						className="ticket-view-container"
						style={{ display: nonTransferredTickets.length > 0 ? 'initial' : 'none' }}>
						<div
							className="ticket-view-type-count-title"
							key={`${index}ticket-view-title`}
							tabIndex={0}
							aria-label={`${
								currTicketName && currTicketName[0].toLowerCase() === 'grounds pass'
									? 'grounds passes'
									: currTicketName
							} (${nonTransferredTickets.length} ticket${nonTransferredTickets.length === 1 ? '' : 's'})`}
							alt={`${
								currTicketName && currTicketName[0].toLowerCase() === 'grounds pass'
									? 'grounds passes'
									: currTicketName
							} (${nonTransferredTickets.length} ticket${
								nonTransferredTickets.length === 1 ? '' : 's'
							})`}>
							<span tabIndex={-1} aria-hidden>
								{currTicketName && currTicketName[0].toLowerCase() === 'grounds pass'
									? 'grounds passes'
									: currTicketName}{' '}
								({nonTransferredTickets.length} ticket{nonTransferredTickets.length === 1 ? '' : 's'})
							</span>
						</div>
						{nonTransferredTickets.map((value, index) => {
							let imageObjVal;
							if (value.source.details && value.source.details.main) {
								imageObjVal = value.source.details.main.find(
									({ key }) => key.toLowerCase() === 'court'
								);
							}
							return (
								<div key={`${index}${value.externalId}-container`}>
									{renderWebViewStub(
										value,
										imageObjVal,
										index,
										value?.status?.value?.toLowerCase() === 'expired'
									)}
								</div>
							);
						})}
					</div>
				);
			});

			transferredTicketsLength = transferredTicketsLength + transferredTickets.length;

			renderTicketStubTransfer = (
				<div key={`ticket-view-container-transferredTix`} className="ticket-view-container">
					{transferredTickets.map((value, index) => {
						let imageObjVal;
						let label = value.message ? `${value.typeLabel} - ${value.message}` : value.typeLabel;
						if (value.source.details && value.source.details.main) {
							imageObjVal = value.source.details.main.find(({ key }) => key.toLowerCase() === 'court');
						}
						return (
							<div key={`${index}${value.externalId}-container`}>
								{renderWebViewStub(value, imageObjVal, index, true)}
							</div>
						);
					})}
				</div>
			);

			returnedTicketsLength = returnedTicketsLength + returnedTickets.length;

			renderTicketStubReturned = (
				<div key={`ticket-view-container-transferredTix`} className="ticket-view-container">
					{returnedTickets.map((value, index) => {
						let imageObjVal;
						let label = value.message ? `${value.typeLabel} - ${value.message}` : value.typeLabel;
						if (value.source.details && value.source.details.main) {
							imageObjVal = value.source.details.main.find(({ key }) => key.toLowerCase() === 'court');
						}
						return (
							<div key={`${index}${value.externalId}-container`}>
								{renderWebViewStub(value, imageObjVal, index, true)}
							</div>
						);
					})}
				</div>
			);
		}

		return (
			<React.Fragment>
				<div
					style={{ display: !showTicketSwap ? 'block' : 'none' }}
					className="ticket-stub-render-container"
					tabIndex={0}
					aria-label="entering ticket list section"
					alt="entering ticket list section">
					{renderTicketStubNonTransfer}
					<div style={{ display: transferredTicketsLength > 0 ? 'initial' : 'none' }}>
						<div
							className="ticket-view-type-count-title"
							tabIndex={0}
							aria-label={`Transferred (${transferredTicketsLength} ticket${
								transferredTicketsLength === 1 ? '' : 's'
							})`}
							alt={`Transferred (${transferredTicketsLength} ticket${
								transferredTicketsLength === 1 ? '' : 's'
							})`}>
							<span tabIndex={-1} aria-hidden>
								Transferred (
								{`${transferredTicketsLength} ticket${transferredTicketsLength === 1 ? '' : 's'}`})
							</span>
						</div>
						{renderTicketStubTransfer}
					</div>
					<div style={{ display: returnedTicketsLength > 0 ? 'initial' : 'none' }}>
						<div
							className="ticket-view-type-count-title"
							tabIndex={0}
							aria-label={`Returned (${returnedTicketsLength} ticket${
								returnedTicketsLength === 1 ? '' : 's'
							})`}
							alt={`Returned (${returnedTicketsLength} ticket${returnedTicketsLength === 1 ? '' : 's'})`}>
							<span tabIndex={-1} aria-hidden>
								Returned ({`${returnedTicketsLength} ticket${returnedTicketsLength === 1 ? '' : 's'}`})
							</span>
						</div>
						{renderTicketStubReturned}
					</div>
				</div>
				{!showTicketSwap ? null : (
					<TicketSwapInitial
						onClose={toRefresh => {
							setShowTicketSwap(false);
							props.onHideDayNavigator(false);
							if (toRefresh) {
								props.onRefreshData();
							}
						}}
						fullTicketValue={ticketSwapData}
						title=""
						ticketStubToRender={ticketSwapStub}
						callTicketActions={props.callTicketActions}
					/>
				)}
			</React.Fragment>
		);
	} else {
		return null;
	}
};
export default TicketList;

import React, { Component, Fragment } from 'react';
import { TicketsContext } from '../context';
import op from 'object-path';
import LoadingIndicator from 'appdir/components/common-ui/LoadingIndicator';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';
import { getQuerystringParam } from 'appdir/components/general/Util';
import isEqual from 'lodash/isEqual';
import MeasurementUtils from 'appdir/lib/analytics';
import DesktopTicketStub from './DesktopTicketStub';
import AppPromo from '../elementsFunc/AppPromo';
import DesktopTicketActionModal from './DesktopTicketActionModal';
import DesktopTicketListTable from './DesktopTicketListTable';
import TicketManagementMultipleStubs from '../elements/TicketActionViews/TicketManagementMultipleStubs';
import SingleTicketStub from '../elements/TicketActionViews/SingleTicketStub';
import CloseBtn from 'appdir/components/common-ui/CloseBtn';
import { courtImageObj } from 'appdir/components/pages/Tickets/elements/consts.js'

const mobileMatch = window.matchMedia('(max-width: 767px)');

class DesktopTicketView extends Component {
	constructor(props) {
		super(props);

		this.state = {
			...props,
			dataUpdated: false,
			sortOnLastUpdated: '',
			isMobile: mobileMatch.matches,
			displayPromoPage: false,
		};

		this.allTickets = []; // collection of all tickets to be used for comparison
		this.sortedTicketData = []; // all ticket data sorted by date and court (and last updated if selected)
		this.filterDate = null;
		this.filterCourt = null;
		this.filterStatus = null;
		this.filterType = null;

		//logger.log('[Tickets - View] constructor - state:%o context:%o', this.state, this.state.context);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		logger.log('[DesktopTicketView] componentWillReceiveProps - prev:%o next:%o', this.state, nextProps);
		this.setState(prevState => {
			return {
				...prevState,
				...nextProps,
			};
		});
	}

	componentDidMount() {
		//logger.log('[Tickets - View] componentDidMount - state:%o context:%o', this.state, this.state.context);

		this.debugView = getQuerystringParam(null, 'ticketDebug');
		MeasurementUtils.dispatchMeasurementCall('View Tickets', { pageTitle: 'MyWimbledon' });
	}

	componentDidUpdate(prevProps, prevState) {
		// logger.log('[Tickets - View] componentDidUpdate - prevProps:%o', prevProps);

		/**
		 * if the current context ticket data is different from the previous
		 * ticket data then sort the new ticket data
		 */
		if (this.props.context.filters !== prevState.context.filters) {
			this.setState({ context: this.props.context })
		}
		if (
			this.state.context.ticketData && 
			!isEqual(this.state.context.ticketData, this.allTickets) ||
			prevState.sortOnLastUpdated !== this.state.sortOnLastUpdated ||
			(this.props.resetClicked && this.state.sortOnLastUpdated !== '')
		) {
			logger.log('[Tickets - View] componentDidUpdate - ticket data has changed');
			this.allTickets = this.state.context.ticketData;

			if (this.props.resetClicked && this.state.sortOnLastUpdated !== '') {
				this.setState({ sortOnLastUpdated: '' });
				this.sortedTicketData = this.sortTicketData(this.state.context.ticketData);
			} else if (this.state.context.ticketData && this.state.context.orderedCourts) {
				this.sortedTicketData = this.sortTicketData(this.state.context.ticketData);

				if (this.sortedTicketData.length > 0) {
					// call getActionableTickets to set again after ticket data is refreshed
					this.getActionableTickets();

					this.setState({
						...this.state,
						dataUpdated: true,
					});
				}
			}

			if (!this.filterDate && !this.filterCourt && !this.filterStatus && !this.filterType) {
				this.getActionableTickets();
			}
		}

		/**
		 * if a filter has changed, update the actionable tickets
		 */
		if (
			this.filterDate !== op.get(this.state.context, 'filters.date', null) ||
			this.filterCourt !== op.get(this.state.context, 'filters.court', null) ||
			this.filterStatus !== op.get(this.state.context, 'filters.status', null) ||
			this.filterType !== op.get(this.state.context, 'filters.type', null)
		) {
			// logger.log(
			// 	'[Tickets - View] componentDidUpdate  - a filter has changed - this.state.context.filters:%o',
			// 	op.get(this.state.context, 'filters', null)
			// );

			/**
			 * set filter data into variables
			 */
			this.filterDate = op.get(this.state.context, 'filters.date', null);
			if (op.get(this.state.context, 'orderedCourts', []).length > 0 && op.get(this.state.context, 'filters.court', null)) {
				this.filterCourt = this.state.context.orderedCourts.filter(crt => {
					if (crt.code.toLowerCase() == this.state.context.filters.court.toLowerCase()) {
						return true;
					}
				})[0].code;
			} else {
				this.filterCourt = null;
			}
			this.filterStatus = op.get(this.state.context, 'filters.status', null);
			this.filterType = op.get(this.state.context, 'filters.type', null);

			this.getActionableTickets();
		}
	}

	/**
	 * getActionableTickets
	 * @param {*} tickets
	 * @returns array of tickets that can be acted upon
	 *
	 * This function looks at the data to see which tickets can and can't be acted upon
	 */
	getActionableTickets() {
		let actionableTickets = [];

		let date = !this.filterDate ? '' : this.filterDate;
		let court = !this.filterCourt ? '' : this.filterCourt;
		let status = !this.filterStatus ? '' : this.filterStatus;
		let type = !this.filterType ? '' : this.filterType;

		// logger.log('[Tickets - View] getActionableTickets - court:%o', court);

		this.sortedTicketData.map((ticket, i) => {
			let formattedDate = op.get(ticket, 'date', null);

			/**
			 * if the ticket data matches any of the filters then it
			 * is an actionable ticket or if there are no selected filters
			 */
			if (
				(date == '' && court == '' && status == '' && type == '') ||
				(((formattedDate && date.indexOf(formattedDate) == 0) || date == '') &&
					((op.get(ticket, 'detailedInformation.courtCode', '') &&
						ticket.detailedInformation.courtCode == court) ||
						court == '') &&
					((op.get(ticket, 'status.filtervalue', '') && ticket.status.filtervalue == status) ||
						status == '') &&
					((op.get(ticket, 'typeLabel', '') && ticket.typeLabel == type) || type == ''))
			) {
				actionableTickets.push(ticket);
				op.set(ticket, 'actionable', true);
			} else {
				op.set(ticket, 'actionable', false);
			}
		});

		// logger.log('[Tickets - View] getActionableTickets - actionableTickets:%o', actionableTickets);

		let actionableTicketIds = actionableTickets.map(a => {
			return a.externalId;
		});

		// logger.log('[Tickets - View] getActionableTickets- actionableTicketIds:%o', actionableTicketIds);

		this.sortedTicketData.forEach(s => {
			if (actionableTicketIds.includes(s.externalId)) {
				op.set(s, 'actionable', true);
			} else {
				op.set(s, 'actionable', false);
			}
		});

		// logger.log('[Tickets - View] getActionableTickets- actionableTickets:%o', actionableTickets);
		// logger.log('[Tickets - View] getActionableTickets- this.sortedTicketData:%o', this.sortedTicketData);
	}

	refreshData() {
		//logger.log('[Tickets - View] refresh Data');
		this.props.onUpdate('refresh', null);
	}

	hasWheelchairTickets() {
		let tickets = op.get(this.state.context, 'ticketData', []);
		let wheelchairTickets = [];
		let _this = this;

		if (tickets.length > 0) {
			wheelchairTickets = tickets.filter(t => {
				return _this.isWheelchairTicket(t.detailedInformation.seat);
			});
		}

		if (wheelchairTickets.length > 0) {
			return true;
		} else {
			return false;
		}
	}

	isWheelchairTicket(seat) {
		let lastChar;

		lastChar = seat.charAt(seat.length - 1);

		return lastChar.toUpperCase() != lastChar.toLowerCase();
	}

	sortTicketData(tickets) {
		let sorted;
		let ordered_courts = this.state.context.orderedCourts.sort((a, b) => {
			return a.prestige - b.prestige;
		});

		const { sortOnLastUpdated } = this.state;

		sorted = tickets.sort((a, b) => {
			let c1 = ordered_courts.findIndex(crt => crt.code === op.get(a, 'detailedInformation.courtCode', ''));
			let c2 = ordered_courts.findIndex(crt => crt.code === op.get(b, 'detailedInformation.courtCode', ''));
			let r1 = op.get(a, 'detailedInformation.row', '');
			let r2 = op.get(b, 'detailedInformation.row', '');
			let s1 = parseInt(op.get(a, 'detailedInformation.seat', '')) || 0;
			let s2 = parseInt(op.get(b, 'detailedInformation.seat', '')) || 0;

			let d1 = a.source.event.startTime
				? moment(a.source.event.startTime)
						.tz('Europe/London')
						.startOf('day')
						.valueOf()
				: moment(0)
						.add(900, 'years')
						.valueOf();
			let d2 = b.source.event.startTime
				? moment(b.source.event.startTime)
						.tz('Europe/London')
						.startOf('day')
						.valueOf()
				: moment(0)
						.add(900, 'years')
						.valueOf();
			let l1 =
				a?.status?.filtervalue?.toLowerCase() !== 'normal' // make sure there actual status value
					? moment(a?.status?.date)?.valueOf()
					: 0;
			let l2 =
				b?.status?.filtervalue?.toLowerCase() !== 'normal' // make sure there actual status value
					? moment(b?.status?.date)?.valueOf()
					: 0;

			if (sortOnLastUpdated === '') {
				if (d2 < d1) return 1;
				if (d1 < d2) return -1;
				if (c2 < c1) return 1;
				if (c1 < c2) return -1;
				if (r2 < r1) return 1;
				if (r1 < r2) return -1;
				if (s2 < s1) return 1;
				if (s1 < s2) return -1;
				return 0;
			} else if (sortOnLastUpdated) {
				if (l1 == 0) return 1;
				if (l2 == 0) return -1;
				if (l2 < l1) return -1;
				if (l1 < l2) return 1;
				if (d2 < d1) return 1;
				if (d1 < d2) return -1;
				if (c2 < c1) return 1;
				if (c1 < c2) return -1;
				if (r2 < r1) return 1;
				if (r1 < r2) return -1;
				if (s2 < s1) return 1;
				if (s1 < s2) return -1;
				return 0;
			} else {
				if (l1 == 0) return 1;
				if (l2 == 0) return -1;
				if (l2 < l1) return 1;
				if (l1 < l2) return -1;
				if (d2 < d1) return 1;
				if (d1 < d2) return -1;
				if (c2 < c1) return 1;
				if (c1 < c2) return -1;
				if (r2 < r1) return 1;
				if (r1 < r2) return -1;
				if (s2 < s1) return 1;
				if (s1 < s2) return -1;
				return 0;
			}
		});

		return sorted;
	}

	onOpenActionModal() {
		const { fullTicketValue, title, ticketStub, onClose } = this.state.openModalValues;

		// body of the modal for the action
		return (
			<DesktopTicketActionModal
				closeModal={which => {
					this.setState({
						openModal: false,
						openModalValues: {},
					});
					if (this.props.actionsModalDisplay) this.props.onActionsModalDisplay(false);
					onClose();
					if (which === 'refresh') {
						this.props.onUpdate(which);
						// refresh the list
						this.props.onRefreshTicketData();
					}
				}}
				fullTicketValue={fullTicketValue}
				title={title}
				ticketStubToRender={ticketStub}
				renderLargeTransferModal
				refreshData={() => this.refreshData()}
				onRefreshTicketData={() => this.props.onRefreshTicketData()}
				ticketData={this.sortedTicketData}
				onRenderMultipleTicketStubs={(value, err, email) => this.onRenderMultipleTicketStubs(value, err, email)}
				onRenderSingleTicketStub={(value, err, email) => this.onRenderSingleTicketStub(value[0], err, email)}
			/>
		);
	}

	setTicketStatus() {
		// logger.log('[Tickets - View] setTicketStatus');

		this.sortedTicketData.forEach((ticket, i) => {
			/**
			 * if ticket is actionable, set disabled to false, else set disabled to true
			 */
			if (ticket.actionable) {
				op.set(ticket, 'disabled', false);
			} else {
				op.set(ticket, 'disabled', true);
			}
		});

		// logger.log('[Tickets - View] setTicketStatus - this.sortedTicketData:%o', this.sortedTicketData);
	}

	onRenderTicketStub(ticket, index) {
		logger.log('onRenderTicketStub() ticket prop=',ticket)
		logger.log('onRenderTicketStub() ticket.status=',ticket.status)
		let imageObjVal;
		if (ticket.source.details && ticket.source.details.main) {
			imageObjVal = ticket.source.details.main.find(({ key }) => key.toLowerCase() === 'court');
		}

		return (
			<DesktopTicketStub
				middleContents={{
					court: ticket.detailedInformation.court,
					type: ticket.typeLabel,
					gangway: ticket.detailedInformation.gangway ? `Gangway ${ticket.detailedInformation.gangway}` : '',
					row: ticket.detailedInformation.row ? `Row ${ticket.detailedInformation.row}` : '',
					seat: ticket.detailedInformation.seat ? `Seat ${ticket.detailedInformation.seat}` : '',
					status: ticket.status,
				}}
				imageToRender={
					courtImageObj[imageObjVal ? imageObjVal.value.toLowerCase() : ''] ||
					'/assets/images/tickets/Tickets_GroundsPass.png'
				} // have a default image in case no match
				id={ticket.externalId}
				fullTicketValue={ticket}
				index={index}
				tixMsg={ticket.message}
				openActionModal={(fullTicketValue, title, ticketStub, onClose) => {
					if (!this.state.openModal)
						this.setState({
							openModal: true,
							openModalValues: { fullTicketValue, title, ticketStub, onClose },
						});
				}}
				onActionsModalDisplay={val => this.props.onActionsModalDisplay(val)}
				onDisplayPromoPage={() => {
					this.setState({ displayPromoPage: true });
					this.props.onDisplayAppPromo(true);
				}}
				// showModal={this.props.showModal}
			/>
		);
	}

	onRenderSingleTicketStub(ticket, err) {
		let imageObjVal;
		if (ticket?.source?.details?.main) {
			imageObjVal = ticket.source?.details?.main.find(({ key }) => key.toLowerCase() === 'court');
		}

		return (
			<SingleTicketStub
				middleContents={{
					court: ticket?.detailedInformation?.court,
					type: ticket?.typeLabel,
					gangway: ticket?.detailedInformation?.gangway
						? `Gangway ${ticket?.detailedInformation?.gangway},`
						: '',
					row: ticket?.detailedInformation?.row ? `Row ${ticket?.detailedInformation?.row},` : '',
					seat: ticket?.detailedInformation?.seat ? `Seat ${ticket?.detailedInformation?.seat}` : '',
				}}
				imageToRender={
					courtImageObj[imageObjVal ? imageObjVal.value.toLowerCase() : ''] ||
					'/assets/images/tickets/Tickets_GroundsPass.png'
				} // have a default image in case no match
				id={ticket?.externalId}
				error={err}
				date={ticket?.day !== 'UGP' ? ticket.date : ''}
			/>
		);
	}

	onRenderMultipleTicketStubs(tickets, err, email) {
		let ticketStubs = (
			<div>
				<TicketManagementMultipleStubs data={tickets} error={err} email={email} />
			</div>
		);

		return ticketStubs;
	}

	render() {
		logger.log(
			'[Tickets - View] render - state:%o, context:%o, this.sortedTicketData:%o, this.filterAction:%o',
			this.state,
			this.state.context,
			this.sortedTicketData
		);
		if (this.sortedTicketData?.length && this.state.context.ticketComplete !== null) {
			/**
			 * disable tickets that are not actionable for the selected filter
			 */
			this.setTicketStatus();

			/**
			 * filter this.sortedTicketData to get tickets to be displayed
			 */
			this.displayTickets = this.sortedTicketData.filter(t => {
				return !t.disabled;
			});

			if (this.displayTicket?.length && this.props.ticketLengthSet === '') {
				this.props.onUpdateTicketsLength(this.displayTickets.length);
			}

			// logger.log('[Tickets - View] render - this.displayTickets:%o', this.displayTickets);

			let wcTickets = this.hasWheelchairTickets();
			return (
				<>
					{this.state.openModal ? this.onOpenActionModal() : null}

					<div
						className="desktopTicketView-cntr"
						style={{ display: this.state.openModal ? 'none' : 'initial' }}>
						<Fragment>
							{this.state.context.ticketComplete === true ? (
								<div className={`ticket-extras${!wcTickets ? ' single-element' : ''}`}>
									{wcTickets ? <div className="wheelchair-legend">WC: Wheelchair Seat</div> : null}
								</div>
							) : null}
						</Fragment>
						{this.state.displayPromoPage ? (
							<div style={{ width: '100%' }}>
								<CloseBtn
									className="show-full-tix-close-btn"
									closeFn={() => {
										this.setState({ displayPromoPage: false });
										this.props.onDisplayAppPromo(false);
									}}
								/>
								<AppPromo />
							</div>
						) : this.props.ticketManagementChecked ? (
							<div className="desktop-ticket-list-table-cntr">
								<DesktopTicketListTable
									debugView={this.debugView}
									data={this.sortedTicketData}
									ticketActionTabSelected={this.props.ticketActionTabSelected}
									onActionBtnClick={(title, values) => {
										let multipleTicketStubs = this.onRenderMultipleTicketStubs(values);
										this.props.onActionsModalDisplay(true);
										this.setState({
											openModal: true,
											openModalValues: {
												fullTicketValue: values,
												title,
												ticketStub:
													values.length > 1
														? multipleTicketStubs
														: this.onRenderSingleTicketStub(values[0]),
												onClose: () => this.setState({ openModal: false }),
											},
										});
									}}
									orderedCourts={this.state.context.orderedCourts}
									resetClicked={this.props.resetClicked}
									onSetReset={() => this.props.onSetReset()}
								/>
							</div>
						) : (
							<div className="ticketview-table">
								{this.state.isMobile ? null : (
									<div className="row headers web-ticket-headers">
										<div className="cell row-content">
											<div className="cell web-ticketview-header-med"></div>
											<div className="cell web-ticketview-header">Court</div>
											<div className="cell web-ticketview-header">Date</div>
											<div className="cell web-ticketview-header-large">Ticket Details</div>
											<div className="cell web-ticketview-header-type web-ticketview-header-margin">
												Type
											</div>
											<div className="cell web-ticketview-header-extralarge">Status</div>
											<div className="cell web-ticketview-header web-ticketview-header-last">
												Last Updated
												<div
													className="web-ticketview-sort-icon-container"
													onClick={() => {
														if (this.props.resetClicked) this.props.onSetReset();
														this.setState({
															sortOnLastUpdated:
																this.state.sortOnLastUpdated === ''
																	? true
																	: this.state.sortOnLastUpdated === true
																	? false
																	: '',
														});
													}}>
													<img
														src="/assets/images/tickets/sortArrowIcon.svg"
														alt="sort arrow icon"
														className={
															this.state.sortOnLastUpdated ||
															this.state.sortOnLastUpdated === ''
																? 'web-ticketview-sortIcon-rotate'
																: 'web-ticketview-sortIcon'
														}
													/>
													{this.state.sortOnLastUpdated === '' ? (
														<img
															alt="sort arrow icon default"
															src="/assets/images/tickets/sortArrowIcon.svg"
															className="web-ticketview-sortIcon-default"
														/>
													) : null}
												</div>
											</div>
										</div>
									</div>
								)}
								<div
									className="no-tickets-available row"
									style={{ display: this.displayTickets.length == 0 ? 'block' : 'none' }}>
									There are no tickets available.
								</div>
								{this.displayTickets?.length > 0 &&
									!this.state.openModal &&
									this.displayTickets?.map((d, i) => {
										return (
											<div
												className="ticket-view-ticket-stub-container-div web-ticketview-stubs-container"
												key={`ticketview-row-${i}`}>
												{this.onRenderTicketStub(d, i)}
												{this.state.ticketActionsDropdownOpen === i ? (
													<div className="web-ticket-stub-actions-dropdown-container">
														<div className="web-ticket-stub-actions-dropdown">
															{actionsArr.map((val, index) => {
																return (
																	<button
																		className="web-ticket-dropdown-button"
																		id={val.value}
																		role="button"
																		key={index}
																		disabled={
																			(d?.status?.filtervalue?.toLowerCase() ===
																				'normal' ||
																				d?.status?.filtervalue?.toLowerCase() ===
																					'received') &&
																			d?.actions[0]?.toLowerCase() !==
																				'none-controlled'
																				? !d?.actions
																						.concat('show')
																						.includes(val.value)
																				: !d?.actions?.includes(val.value)
																		}
																		onClick={event => {
																			if (event.target.id === 'show') {
																				this.setState({
																					ticketActionsDropdownOpen: '',
																					displayPromoPage: true,
																				});
																			} else {
																				this.setState({
																					ticketActionsDropdownOpen: '',
																				});
																				document.querySelector(
																					'body'
																				).style.overflow = 'hidden';
																			}
																		}}>
																		{val.label}
																	</button>
																);
															})}
														</div>
													</div>
												) : null}
												{this.debugView ? (
													<div className="ticketview-details-id ticketview-details-id-debug ticketview-details">
														<span>
															<strong>SPEC:</strong> {d.spectatorId}
														</span>
														<span>
															<strong>&emsp; ID:</strong> {d.source.id.split('@')[1]}
														</span>
														<span>
															<strong>&emsp; GRP:</strong>{' '}
															{d.source.groupId
																? d.source.groupId.replace('www.wimbledon.com@', '')
																: ''}
														</span>
														<span>
															<strong>&emsp; CAT:</strong>{' '}
															{d.detailedInformation.category}{' '}
														</span>
														<span>
															<strong>&emsp; ACTIONS:</strong> {d.actions.join(', ')}
														</span>
													</div>
												) : null}
											</div>
										);
									})}
							</div>
						)}
					</div>
				</>
			);
		} else {
			return <LoadingIndicator />;
		}
	}
}

DesktopTicketView.contextType = TicketsContext;
export default DesktopTicketView;

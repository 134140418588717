import deps from 'dependencies';
import axios from 'axios';
import op from 'object-path';
import { restHeaders, sortTicketData } from 'appdir/components/general/Util';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';

import { formatTickets, formatTransfers, appendStatus, appendActionDate } from './Data';

const fetch = path => {
	let hdr = restHeaders();
	//logger.log('[ContentPageSecure] fetchContent - path:%o uid:%o', path, uid, sig, timestamp);

	return axios
		.get(path, { headers: hdr })
		.then(response => {
			if (response.status === 200) {
				return response.data;
			}
		})
		.catch(error => {
			if (error.response) {
				//logger.log('[ContentPageSecure] fetchContent - error1:%o', error.response);
				throw error.response;
			} else {
				//logger.log('[ContentPageSecure] fetchContent - error2:%o resp:%o', error, error.response);
				throw error;
			}
		});
};

/**
 * Calls TixNGo spectator APIs through SDK
 *   insure have valid JWT and id token first
 *   calls for all accounts
 *   (this is called from Tickets index)
 * @param {} service
 * @param {} accounts - the spectator accounts
 * @param {*} dispatch
 * @param {*} store
 */
const callSpectatorAll = (service, accounts, dispatch, store) => {
	logger.log('[Tickets] services.callSpectator - service:%s accounts:%o', service, accounts);
	return new Promise((resolve, reject) => {
		//get JWT
		deps.services.Gigya.getJWT(dispatch, store)
			.then(token => {
				logger.log('[Tickets] services.callSpectator - has jwt:%o', token);

				let loadPromises = [];
				accounts.forEach((account, idx) => {
					loadPromises.push(loadSpectatorTickets(account, service, dispatch, store));
				});

				Promise.allSettled(loadPromises)
					.then(result => {
						logger.log('[Tickets] services.callSpectatorAll - allSettled:%o', result);
						// end process if 204 so app can send data 
						if (result[0]?.status == "rejected" && result[0]?.reason?.status == 204) {
							// don't return anything so app times out
							setTimeout(() => {
								// resolve after 30 seconds to handle scenario of user not having tickets cached, so that loader wont show forever
								resolve({
									tickets: [],
									complete: true,
									error: result[0]?.reason,
								}); 
							}, 30000);
						} else {
							// add error handling/codes here since since all spectator resolved first to decide what to show
							let data = {
								tickets: [],
								complete: true,
								error: result[0].status === 'rejected' ? result[0].reason : '',
							};
							result.forEach((res, idx) => {
								if (res.status == 'fulfilled') {
									data.tickets = data.tickets.concat(res.value.tickets);
									data.systemTime = res.value.systemTime;
									data.serverTime = Math.round(res.value.serverTime / 1000);
									if (!res.value.complete) {
										data.complete = false;
									}
								}
							});
							//logger.log('[Tickets] services.callSpectatorAll - allTickets:%o', allTickets);
							resolve(data);
						}
					})
					.catch(error => {
						logger.error('[Tickets] services.callSpectatorAll - allSettled:%o', error);
						reject(error);
					});
			})
			.catch(error => {
				logger.error('[Tickets] services.callSpectatorAll - error:%o', error);
				reject({
					error: 'error getting token',
					code: 'E2010',
				});
			});
	});
};

/**
 * After Gigya JWT is loaded, this next step in trigger profile loads, then tickets
 *  STEP 1
 * @param {*} account
 * @param {*} service
 * @param {*} dispatch
 * @param {*} store
 * @returns
 */
const loadSpectatorTickets = (account, service, dispatch, store) => {
	logger.info('[Tickets] services.loadSpectatorTickets - account:%o ', account);

	return new Promise((resolve, reject) => {
		//prepare API call
		const { Config, Gigya } = store.getState();

		let profileData = {
			id: account.value,
			firstName: Gigya?.currentUser?.profile?.firstName,
			lastName: Gigya?.currentUser?.profile?.lastName,
		};

		deps.services.Gigya.getJWT(dispatch, store).then(token => {
			profileData.idToken = token?.jwt?.id_token;

			getSpectatorProfile(profileData, Config.tickets.api.profile)
				.then(response => {
					logger.info('[Tickets] services.getSpectatorProfile - account:%o response:%o ', account, response);
					profileData.firstName = response?.data?.response?.firstName || profileData.firstName;
					profileData.lastName = response?.data?.response?.lastName || profileData.lastName;

					getSpectatorTickets(service, profileData, Config.tickets.api.spectator, store)
						.then(data => {
							resolve(data);
						})
						.catch(error => {
							reject(error);
						});
				})
				.catch(error => {
					//logger.error('[Tickets] services.getSpectatorProfile - error:%o ', error);
					reject(error);
				});

		});
	});
};

/**
 * after the spectator profile is loaded, then we start getting tickets
 *  STEP 2
 * @param {*} account
 * @param {} idToken
 * @param {} base - the base bath to the spectator api
 */
const getSpectatorTickets = async (service, dataVal, base, store) => {
    const { Config } = store.getState();
    let courts = Config.ticketConfig.ticketCourts;
    let tickets = [];
    let history = [];

    let headers = {
        Authorization: `Bearer ${dataVal.idToken}`,
        Accept: 'application/json',
    };

    let data = { sid: dataVal?.id };
    logger.log('[Tickets] services.getSpectatorTickets - data:%o headers:%o', data, headers);

    try {
        let viewTixPath = Config.tickets.api.tickets;
        let response = await axios.post(viewTixPath, data, { headers });
        
        if (response.status === 200) {
            let returnObject = {
                tickets: null,
                complete: op.get(response, 'data.tickets.status.complete', false),
                serverTime: response?.data?.serverTime,
                systemTime: moment().unix(),
            };

            tickets = formatTickets(response?.data?.tickets, true, dataVal, store);

            let limit = Config?.tickets?.api?.ticketPaginationLimit;
            let offset = 0;

            const fetchTicketHistory = async () => {
                while (true) {
                    let historyResponse = await getTicketHistory(service, headers, Config?.tickets?.api?.ticketHistory, data, offset, limit);
                    let formattedHistory = formatTransfers(historyResponse?.data?.transferHistory, false, data, store, tickets);

                    if (formattedHistory?.length) {
                        tickets = tickets.concat(formattedHistory);
                    }

                    tickets = appendActionDate(tickets, historyResponse?.data?.transferHistory);
                    tickets = appendStatus(tickets, data.sid, Config.tickets);

                    if (historyResponse?.data?.transferHistory?.length < limit) break;
                    offset += limit;
                }
            };

            await fetchTicketHistory();
            logger.log('[Tickets] services.getSpectatorTickets getTicketHistory() - completed');

            let pendingTickets = tickets.filter(ticket => ticket.source.state === 'pending');

            if (pendingTickets.length > 0) {
                try {
                    let transferResponse = await axios.post(Config?.tickets?.api?.ticketTransfers, data, { headers });
                    let transfers = transferResponse.data?.transfers || [];

                    tickets.forEach((ticket, idx) => {
                        if (ticket.source.state === 'pending') {
                            let matchingTransfer = transfers.find(transfer => transfer.ticket.id === ticket.source.id);
                            if (matchingTransfer) {
                                op.set(ticket, 'source.transfer', {
                                    toEmail: matchingTransfer.to,
                                    date: matchingTransfer.date,
                                });
                                tickets[idx] = ticket;
                            }
                        }
                    });
                } catch (error) {
                    logger.error('[Tickets] services.getSpectatorTickets - transfer error:%o', error);
                }
            }

            tickets = appendStatus(tickets, data.sid, Config.tickets);
            returnObject.tickets = tickets;
            return returnObject;
        } else {
            throw new Error(`Unexpected response status: ${response.status}`);
        }
    } catch (error) {
        logger.error('[Tickets] services.getSpectatorTickets - error:%o', error);
        throw error;
    }
};


// note: new getTicketHistory func 2025 
const getTicketHistory = (service, headers, path, data, offset, limit) => {
	return new Promise((resolve, reject) => {
		if (service == 'view') {
			axios
				// .get(`${base}/transfers/history?limit=3000&offset=0`, { headers })
				.post(`${path}?offset=${offset}&limit=${limit}`, data, { headers })
				.then(response => {
					logger.log('[Tickets] services.getTicketHistory - resp:%o', response);
					if (response.status === 200) {
						resolve(response);
					} else {
						logger.log('[Tickets] services.getTicketHistory - resp:%o', response);
						logger.warn('[Tickets] services.getTicketHistory - no history data');
						resolve({ data: [] });
					}
				})
				.catch(error => {
					logger.warn('[Tickets] services.getTicketHistory - no history data');
					resolve({ data: [] });
				});
		} else {
			resolve({ data: [] });
		}
	});
};

/**
 * get spectator profile
 * @param {} idToken
 * @param {} base - the base bath to the spectator api
 */
const getSpectatorProfile = (dataVal, path) => {
	return new Promise((resolve, reject) => {
		//manual call
		let headers = {
			Authorization: `Bearer ${dataVal.idToken}`,
			Accept: 'application/json'
		};
		let data = {
			sid: dataVal?.id,
		};
		//logger.log('[Tickets] services.getSpectatorProfile - path:%o headers:%o', path, headers);
		axios
			.post(path, data, { headers })
			.then(response => {
				//logger.log('[Tickets] services.getSpectatorProfile - resp:%o', response);
				if (response.status === 200) {
					resolve(response);
				} else {
					//logger.error('[Tickets] services.getSpectatorProfile - response:%o', response);
					let errorResp = {
						message: 'Profile error',
						code: null,
					};
					reject(errorResp);
				}
			})
			.catch(error => {
				//logger.error('[Tickets] services.getSpectatorProfile - error:%o', error);
				reject({
					message: 'Profile error',
					code: 'E3050',
				});
			});
	});
};

/**
 * Calls TixNGo spectator APIs through SDK
 *   insure have valid JWT and id token first
 * @param {} service
 * @param {} id - the spectator id
 * @param {} tickets_data - ticket data for actioning
 * @param {*} dispatch
 * @param {*} store
 */
const callTicketActions = (service, id, tickets_data, dispatch, store) => {
	logger.log('[Tickets] services.callTicketActions - service:%s id:%s', service, id);
	return new Promise((resolve, reject) => {
		//get JWT
		deps.services.Gigya.getJWT(dispatch, store)
			.then(token => {
				logger.log('[Tickets] services.callTicketActions - has jwt:%o', token);

				//prepare API call
				const { Config, Tickets, Gigya } = store.getState();
				let specBasePath = Config.tickets.api.spectator;
				let transferPath = Config.tickets.api.transfer;
				let exchangePath = Config.tickets.api.exchange;
				// new ticket API additions
				let returnPath = Config.tickets.api.return;
				let recallPath = Config.tickets.api.recall;

				logger.log('[Tickets] services.callTicketActions - Tickets:%o', specBasePath);

				switch (service) {
					case 'recall':
						recallTickets(token.jwt, tickets_data, id, recallPath)
							.then(data => {
								resolve(data);
							})
							.catch(error => {
								reject(error);
							});
						break;
					case 'return':
						returnTickets(token.jwt, tickets_data, id, returnPath)
							.then(data => {
								resolve(data);
							})
							.catch(error => {
								reject(error);
							});
						break;
					case 'share':
						transferTickets(token.jwt, transferPath, tickets_data)
							.then(data => {
								resolve(data);
							})
							.catch(error => {
								reject(error);
							});
						break;
					case 'transfer':
						transferTickets(token.jwt, transferPath, tickets_data)
							.then(data => {
								resolve(data);
							})
							.catch(error => {
								reject(error);
							});
						break;
					case 'exchange':
						exchangeTickets(token.jwt, exchangePath, tickets_data)
							.then(data => {
								resolve(data);
							})
							.catch(error => {
								reject(error);
							});
						break;
					default:
						reject('no service specified');
						break;
				}
			})
			.catch(error => {
				reject({ error: 'error getting token' });
			});
	});
};

const recallTickets = (idToken, ticket_data, sid, path) => {
	return new Promise((resolve, reject) => {
		let headers = {
			Authorization: `Bearer ${idToken?.id_token}`,
			Accept: 'application/json',
		};

		let results = [];

		async function processCalls(ticketData) {
			for await (const ticket of ticketData) {
				let resp = await makeCall(ticket, path, idToken, sid);
				results.push(resp);
				logger.log('[Tickets] services.recallTickets - ticket:%o results;%o', ticket, results);
			}
			logger.log('[Tickets] services.recallTickets - done, results=',results);
			let isSuccess = true;
			results.map((result) => {
				if (result?.status !== 200 || (result?.data?.response?.failure || result?.data?.response?.error?.length || (result?.data?.response?.status && result?.data?.response?.status !== 200))) { 
					isSuccess = false;
				}
			})
			if (isSuccess) {
				resolve(results);
			} else {
				reject(results);
			}
		}
		processCalls(ticket_data);
	});
};

const makeCall = (ticket, path, token, sid) => {
	let headers = {
		Authorization: `Bearer ${token?.id_token}`,
		Accept: 'application/json',
	};
	let data = {
		sid: sid,
		ticketId: ticket,
	};
	return new Promise((resolve, reject) => {
		axios.post(path, data, {headers})
			.then(resp => {
				//results.push(resp);
				logger.log('[Tickets] services.makeCall - resp:%o', resp);
				resolve(resp);
			})
			.catch(error => {
				results.push('error');
				logger.error('[Tickets] services.makeCall - error1:%o', error);
				reject(error);
			});
	})
}

const returnTickets = (jwtToken, ticket_data, sid, path) => {
	return new Promise((resolve, reject) => {
		let headers = {
			Authorization: `Bearer ${jwtToken.id_token}`,
			Accept: 'application/json',
		};

		ticket_data.map((t, i) => {
			let body = {
				sid: sid,
				ticketId: t.ticketId,
			};

			axios
				.post(path, body, { headers })
				.then(response => {
					logger.log('[Tickets] services.returnTickets - resp:%o', response);
					//documentation says I should be getting 204, but I'm actually getting 201
					if (
						response.status > 199 &&
						response.status < 300 &&
						op.get(response, 'data.response.failure', []).length == 0
					) {
						resolve(response);
					} else {
						reject(response);
					}
				})
				.catch(error => {
					// logger.log('[Tickets] services.returnTickets - error:%o', error);
					reject(error);
				});
		});
	});
};

const transferTickets = (jwtToken, path, transfer_data) => {
	return new Promise((resolve, reject) => {
		let headers = {
			Authorization: `Bearer ${jwtToken.id_token}`,
			Accept: 'application/json',
		};

		let body = {
			sid: transfer_data.sid,
			target: transfer_data.target,
			tickets: transfer_data.tickets,
		};

		logger.log('[Tickets] services.transferTickets - path:%o headers:%o body:%o', path, { headers }, body);

		axios
			.post(path, body, { headers })
			.then(response => {
				logger.log('[Tickets] services.transferTickets - resp:%o', response);
				if (response.status === 200 && op.get(response, 'data.response.failure', []).length == 0) {
					resolve(response);
				} else {
					reject(response);
				}
			})
			.catch(error => {
				// logger.log('[Tickets] services.transferTickets - error:%o', error);
				reject(error);
			});
	});
};

const exchangeTickets = (jwtToken, path, transfer_data) => {
	return new Promise((resolve, reject) => {
		let headers = {
			Authorization: `Bearer ${jwtToken.id_token}`,
			Accept: 'application/json',
		};

		let body = {
			spectatorId1: transfer_data.sid1,
			ticketId1: transfer_data.ticketId1,
			spectatorId2: transfer_data.sid2,
			ticketId2: transfer_data.ticketId2,
		};

		logger.log('[Tickets] services.exchangeTickets - path:%o headers:%o body:%o', path, { headers }, body);

		axios
			.post(path, body, { headers })
			.then(response => {
				logger.log('[Tickets] services.exchangeTickets - resp:%o', response);
				if (response.status === 200 && op.get(response, 'data.response.failure', []).length == 0) {
					resolve(response);
				} else {
					logger.log('[Tickets] services.exchangeTickets - else error:%o', error);
					reject(response);
				}
			})
			.catch(error => {
				logger.log('[Tickets] services.exchangeTickets - error:%o', error);
				reject(error);
			});
	});
};

const verifyTicketStatus = (ticketIds, id, dispatch, store) => {
	logger.log('[Tickets] services.verifyTicketStatus - ticketIds:', ticketIds);
	logger.log('[Tickets] services.verifyTicketStatus - id:', id);
	return new Promise((resolve, reject) => {
		deps.services.Gigya.getJWT(dispatch, store)
			.then(token => {
				logger.log('[Tickets] services.verifyTicketStatus - has jwt:%o', token);

				//prepare API call
				const { Config } = store.getState();
				logger.log('[Tickets] services.verifyTicketStatus - Config:', Config);
				let path = Config?.tickets?.api?.status;

				let headers = {
					Authorization: `Bearer ${token.jwt.id_token}`,
					Accept: 'application/json',
				};

				let body = ticketIds;
				// NOTE: hardcoded values for testing
				// let body = [
				// 	{ ticketId: '352954909637726140700153' },
				// 	{ ticketId: '104884101818523239900242' },
				// 	{ ticketId: 'TCA100006' },
				// ];

				logger.log(
					'[Tickets] services.verifyTicketStatus - path:%o headers:%o body:%o',
					path,
					{ headers },
					body
				);

				axios
					.post(path, body, { headers })
					.then(response => {
						logger.log('[Tickets] services.verifyTicketStatus - resp:%o', response);
						resolve(response);
					})
					.catch(error => {
						logger.log('[Tickets] services.exchangeTickets - error:%o', error);
						reject(error);
					});
			})

			.catch(error => {
				logger.error('[Tickets] services.verifyTicketStatus - error:%o', error);
				reject({
					error: 'error getting token',
					code: 'E2010',
				});
			});
	});
};

const logoutTickets = (dispatch, store, ticketRole) => {
	return new Promise((resolve, reject) => {
		const { Config } = store.getState();
		let path = Config.tickets?.api?.clear;
		//logger.log('[Tickets] services.logoutTickets - ticketRole:%o', ticketRole);

		if (ticketRole && path) {
			// logger.log('[Tickets] services.logoutTickets - Tickets:%o', Tickets);
			let spectatorId = ticketRole.otherId.filter(d => d.type == 'spectatorId')[0].value || null;

			deps.services.Gigya.getJWT(dispatch, store)
				.then(token => {
					let headers = {
						Authorization: `Bearer ${token?.jwt?.id_token}`,
						Accept: 'application/json',
					};
					let data = {
						sid: spectatorId,
					};
					axios
						.post(path, data, { headers })
						.then(response => {
							logger.log('[Tickets] services.logoutTickets - resp:%o', response);
							resolve();
						})
						.catch(error => {
							logger.log('[Tickets] services.logoutTickets - error:%o', error);
							resolve();
						});
				})
				.catch(error => {
					logger.error('[Tickets] services.logoutTickets token - error:%o', error);
					resolve();
				})
		}
		else {
			resolve();
		}
	})
};

export default {
	fetch,
	//callSpectator,
	callSpectatorAll,
	callTicketActions,
	logoutTickets,
	verifyTicketStatus,
};

/* const testCall = (token) => {
	let xhr=new XMLHttpRequest();

	xhr.onreadystatechange=function()
	{
		console.log("TEST XHR - xhr %o:", xhr);
	}

	xhr.onload = function() {
		console.log(`TEST XHR - Loaded: ${xhr.status} ${xhr.response}`);
	  };
	  
	  xhr.onerror = function() { // only triggers if the request couldn't be made at all
		console.log(`TEST XHR - Network Error`);
	  };
	  
	  xhr.onprogress = function(event) { // triggers periodically
		// event.loaded - how many bytes downloaded
		// event.lengthComputable = true if the server sent Content-Length header
		// event.total - total number of bytes (if lengthComputable)
		console.log(`TEST XHR - Received ${event.loaded} of ${event.total}`);
	  };

	var url = "https://pp5-api.tixngo.io/v3/spectator/tickets";

	xhr.open("GET", url);
	xhr.withCredentials = true;
	xhr.setRequestHeader("authorization", token);  
	
	xhr.send();
} */
